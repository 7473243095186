import React from 'react';
import './Footer.css';
import footer from '../images/footer.png';

const Footer = () => {
  return (
    <footer className="footer">
      <img src={footer} alt="Footer Image" className="footer-image" />
    </footer>
  );
};

export default Footer;
