import React, { useState, useEffect } from 'react';
import appLogo from './images/app-logo.png';
import age from './images/18.webp';
import centeredImage from './images/centeredimage.png';
import ss1 from './images/ss1.png';
import ss2 from './images/ss2.png';
import ss3 from './images/ss3.png';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Rating from './Components/Rating';
import Data from './Components/Data';
import './Homepage.css';

const Homepage = () => {

    
    const downloadApk = () => {
        const apkUrl = 'https://sabong.phcasinoapps.com/app/sabong.apk'; // Replace with the actual APK URL
        const link = document.createElement('a');
        link.href = apkUrl;
        link.setAttribute('download', 'sabong.apk');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
      // Call `downloadApk` directly on install button click
      const handleInstallClick = () => {
        downloadApk();
      };
    

  return (
    <div className="homepage">
        <Header/>
        <div className="container">
            <div className="app-header">
            <img src={appLogo} alt="App Logo" className="app-logo" />
                <div className="app-details">
                    <h1 className="app-name">Sabong ACF</h1>
                    <h4 className="developer-name" style={{ color: '#01875f' }}>PHCASINOAPPS</h4>
                    <p className="purchase-info">In-app purchases</p>
                </div>
            </div>
      
      <div className="three-column-section">
        <div className="column">
          <p style={{ fontWeight: '700' }}>4.5★</p>
          <p>109.8K reviews</p>
        </div>
        <div className="separator"></div>
        <div className="column">
          <p style={{ fontWeight: '700' }}>1M+</p>
          <p style={{ fontWeight: '600' }}>Downloads</p>
        </div>
        <div className="separator"></div>
        <div className="column">
          <img style={{ width: '25px' }} src={age} alt="18+" />
          <p>Rated for 18+</p>
        </div>
      </div>
      
      <div className="button-section" style={{ marginTop: '5vh' }}>
        <button onClick={handleInstallClick}  style={{ outline: 'none', border: 'none'}} className="centered-button">Install</button>
      </div>
      
      <div className="image-section">
        <img src={centeredImage} alt="Centered Image" className="centered-image" />
      </div>
      
      <div className="carousel-section">
        <div className="carousel">
          <div className="carousel-images">
            <img src={ss3} alt="Screenshot 1" className="carousel-image" />
            <img src={ss1} alt="Screenshot 2" className="carousel-image" />
            <img src={ss2} alt="Screenshot 3" className="carousel-image" />
          </div>
        </div>
      </div>
      
      <div className="about-section">
        <div className="about-heading">
          <h2>About This Game</h2>
          <span className="arrow-icon">➔</span>
        </div>
        <p>
        Welcome to Sabong Casino – the ultimate destination for live Sabong gaming! Experience the thrill of real-time matches and place your game for a chance to win big. Our platform is easy to use, secure, and packed with excitement. Join the action now and feel the rush of Sabong like never before!
        </p>

        <h3>Updated on</h3>
        <p className="update-date">September 30, 2024</p>
      </div>
      <Data/>
      <Rating/>
      <Footer/>
    </div>


        

    </div>
  );
};

export default Homepage;