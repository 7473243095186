import React from 'react';
import './Rating.css';
import image from '../images/data.png';

const Rating = () => {
  return (
    <div style={styles.container}>
      <img 
        src={image} // Replace with your image URL
        alt="Description of image"
        style={styles.image}
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    borderRadius: '4px',
  },
};

export default Rating;
